import { type FC, Suspense, useState } from 'react';
import {
  View,
  Text,
  Button,
  Link,
  MembershipInterval,
  StripeMembershipIntervalEnum,
} from '@rivallapp/volosports-components';

import { type VoloPassSignUpProps } from './types';
import { VoloPassPlanOptions, VoloPassPromoCode } from './index';
import { PaymentForm } from '../../../../../../features';
import { Loading } from '../../../../../../components';
import { VPTermsAndConditionsCheckbox } from '../../../../../../components/VPTermsAndConditionsPopup';
import MembershipPaymentSummary from './MembershipPaymentSummary';
import {
  VOLO_PASS_ANNUAL_FEE,
  VOLO_PASS_MONTHLY_FEE,
} from '../../../../../../shared/volo-pass-tiers-enum';
import useVoloPassDetailsV2 from '../../../../../../hooks/useVoloPassDetails/useVoloPassDetailsV2';

const VoloPassSignUp: FC<VoloPassSignUpProps> = ({
  organizationVoloPassDetails,
  annualOverride,
  setShowSignUpModal,
  refetch,
}) => {
  // Prevents the button from being clicked while Stripe API is running
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    monthlyDollarPrice = 0,
    annualDollarPrice = 0,
    monthlyPlanId,
    annualPlanId,
    cityName,
  } = organizationVoloPassDetails;

  const {
    // loading,
    error,
    activeVoloPassPlanDetails,
    planStatus,
    acceptedTerms,
    setAcceptedTerms,
    planDetails,
    updatePlanDetails,
    validatePromo,
    getPromoText,
    startPlan,
    reinstatePlan,
    upgradeVoloPassPlan,
    // updating,
    // selectedCity,
  } = useVoloPassDetailsV2({
    annualPlanOverride: annualOverride ? annualPlanId : '',
    city: cityName,
  });

  const { planInterval } = activeVoloPassPlanDetails;
  const isMonthlyVoloPassMember =
    planInterval === MembershipInterval[StripeMembershipIntervalEnum.MONTH].value;

  const onUpgradeClick = async () => {
    setIsUpdating(true);
    if (isMonthlyVoloPassMember) {
      await upgradeVoloPassPlan();
    } else if (!planStatus) {
      await startPlan();
    } else {
      await reinstatePlan();
    }
    refetch(); // this updates the UI to the appropriate membership tier view
    setShowSignUpModal(false);
    setIsUpdating(false);
  };

  if (error)
    return (
      <View>
        <Text>
          There was an error retrieving that city&apos;s data. If this error persists, please
          contact{' '}
          <Link
            href="mailto:info@volosports.com"
            isExternal
            _text={{ color: 'blue.400', textDecoration: 'none' }}
          >
            info@volosports.com
          </Link>
        </Text>
      </View>
    );

  if (isUpdating)
    return (
      <View alignItems="center" marginTop={3}>
        <Loading />
      </View>
    );

  return (
    <View>
      <VoloPassPlanOptions
        isMonthlyVoloPassMember={isMonthlyVoloPassMember}
        monthlyDollarPrice={monthlyDollarPrice}
        annualDollarPrice={annualDollarPrice}
        monthlyPlanId={monthlyPlanId}
        annualPlanId={annualPlanId}
        planDetails={planDetails}
        updatePlanDetails={updatePlanDetails}
      />
      <VoloPassPromoCode
        updatePlanDetails={updatePlanDetails}
        planDetails={planDetails}
        validatePromo={validatePromo}
        getPromoText={getPromoText}
      />
      <PaymentForm />
      <Suspense fallback={<Loading />}>
        <View my={2}>
          <MembershipPaymentSummary
            planDetails={planDetails}
            annualPlanId={annualPlanId}
            annualFeeCents={VOLO_PASS_ANNUAL_FEE * 100}
            monthlyFeeCents={VOLO_PASS_MONTHLY_FEE * 100}
            annualPriceCents={annualDollarPrice! * 100}
            monthlyPriceCents={monthlyDollarPrice! * 100}
            upgradeAnnual={isMonthlyVoloPassMember}
          />
        </View>
      </Suspense>
      <View flexDirection="row" marginTop={2} paddingX={3}>
        <VPTermsAndConditionsCheckbox
          value={acceptedTerms}
          onChange={() => setAcceptedTerms(!acceptedTerms)}
          preText="Accept "
          postText=" to continue"
          _text={{ fontSize: 'xs' }}
          checkboxProps={{}}
        />
      </View>
      <View alignItems="center" marginY={4}>
        <Button
          type="secondary"
          width={175}
          borderRadius={50}
          onPress={onUpgradeClick}
          isDisabled={!acceptedTerms || isUpdating}
        >
          Upgrade Now
        </Button>
      </View>
    </View>
  );
};

export default VoloPassSignUp;
