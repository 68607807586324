import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';
import { capitalize } from '../../../utilities';

interface DailyProgrammingProps
  extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  dailyProgramming_response: boolean;
  dailyPrograms: {
    _id: string;
    program_type: string;
    sport: string;
    status: string;
  }[];
  dailyProgrammingComments?: string | null;
}

const DailyProgramming: React.FC<DailyProgrammingProps> = ({
  dailyProgramming_response = false,
  dailyPrograms = [],
  dailyProgrammingComments,
  children,
  ...props
}) => {
  const formattedList = dailyPrograms?.map((dailyProgram, index) => ({
    _id: dailyProgram._id,
    programName: `Program ${index + 1}`,
    Sport: capitalize(dailyProgram.sport),
    Type: capitalize(dailyProgram.program_type.toLowerCase()),
    Status: dailyProgram.status,
  }));

  return (
    <CollapseForm
      title="Daily Programming"
      view="read"
      toggleQuestion="Did daily programming run during your shift?"
      toggleResponse={dailyProgramming_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.CompositeList listValues={formattedList} parentValue="programName" />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea
          label="General comments/feedback:"
          response={dailyProgrammingComments ?? undefined}
        />
      </Box>
      {children}
    </CollapseForm>
  );
};

export default DailyProgramming;
