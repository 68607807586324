import { capitalize } from '../utils';
import type { ExtractEnum } from 'graphql-schema';

/**
 * Overall status for the shift log.
 *
 * Shift logs will default to the draft state and be updated to __new__ once
 * the document is published.
 * ----
 *
 * __New:__ Shift log is new and no one has been assigned to the overall log
 *
 * __InProgress:__ There is an assignee to the overall log, but at least one
 * individual section is not yet resolved
 *
 * __Resolved:__ All sections within the log are in a “resolved” state
 * */
export const ShiftLogStatus = {
  new: 'new',
  in_progress: 'in_progress',
  resolved: 'resolved',
} as const satisfies { [Status in ExtractEnum<'shift_log_status_enum'>]: Status };

export type ShiftLogStatus = (typeof ShiftLogStatus)[keyof typeof ShiftLogStatus];

/**
 * Section status for the shift log.
 *
 * Sections will default to __new__
 * ----
 *
 * __New:__ Section not yet assigned
 *
 * __Open:__ Section has been assigned (toggled automatically)
 *
 * __InProgress:__ Section is actively being worked on (toggled manually)
 *
 * __Resolved:__ Issue in section has been resolved (toggled manually)
 */
export const ShiftLogSectionStatus = {
  new: 'new',
  open: 'open',
  in_progress: 'in_progress',
  resolved: 'resolved',
} as const satisfies { [Status in ExtractEnum<'shift_log_section_status_enum'>]: Status };

export type ShiftLogSectionStatus =
  (typeof ShiftLogSectionStatus)[keyof typeof ShiftLogSectionStatus];

export const ShiftLogSection = {
  REFEREES: 'referees',
  HOSTS: 'hosts',
  PLAYERS: 'players',
  SHIRTS: 'shirts',
  EQUIPMENT: 'equipment',
  VENUE: 'venue',
  SPONSOR_BAR: 'sponsor_bar',
  DAILY_PROGRAMMING: 'daily_programming',
  TECHNOLOGY: 'technology',
  CONTENT_CAPTURE: 'content_capture',
  MISCELLANEOUS: 'miscellaneous',
} as const;

export type ShiftLogSection = (typeof ShiftLogSection)[keyof typeof ShiftLogSection];

export const ShiftLogSectionsToFormattedNameMap = Object.values(ShiftLogSection).reduce(
  (acc, section) => ({
    ...acc,
    [section]: capitalize(section.replaceAll('_', ' ')),
  }),
  {} as Record<ShiftLogSection, string>
) satisfies Record<ShiftLogSection, string>;
