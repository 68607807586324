import { onError } from '@apollo/client/link/error';
import sendToSentry from '../sentryErrorHandler';
import { Auth } from '../../zustand-stores/AuthStore';
import hasuraClient from '../hasuraClient';

const errorLogLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const str = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
        locations
      )}, Path: ${path}`;
      console.error(str);
      sendToSentry(new Error(str));
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }

  // When current user's roles are updated while logged in, we get this error and should force a logout.
  if (response) {
    const message = response.errors?.[0]?.message;
    if (message === 'Your requested role is not in allowed roles') {
      alert('Your roles have been updated. Please log in again.');
      Auth.resetState();
      hasuraClient.resetStore();

      // Force refresh all local state and queries
      window.location.reload();
    }
  }
});

export default errorLogLink;
