import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import EditPermit from '../EditPermit/EditPermitContainer';
import styles from '../permit-styles.module.scss';
import { Button, ConfirmButton, Popup } from '../../../components';
import { Checkbox } from '../../../components/fancyFieldsV2';
import withCurrentUser from '../../../hocs/withCurrentUser';
import edit from '../../../images/icons/icon_edit.svg';
import trash from '../../../images/icons/trash.svg';
import { formatCents } from '../../../utils';

const cx = classNames.bind(styles);

export const getPermitTimeframe = ({ start, end, timezone }) => {
  const date = moment(start).tz(timezone).format('YYYY/MM/DD');
  const startTime = moment(start).tz(timezone).format('HH:mm');
  const endTime = moment(end).tz(timezone).format('HH:mm');
  return {
    date: moment(date, 'YYYY/MM/DD').toDate(),
    startTime,
    endTime,
    timezone,
  };
};

const ViewPermit = props => {
  const {
    onDelete,
    confirmDelete,
    timezone,
    permit,
    onEdit,
    onSelect,
    selected,
    organizationId,
    hideLeagues,
  } = props;

  const { _id, note, centsDaily, start, end, centsHourly, referenceId, sublocations, leagues } =
    permit;

  const { date, startTime, endTime } = getPermitTimeframe({
    start,
    end,
    timezone,
  });

  const [showEdit, toggleEdit] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className={cx('item')}>
        <div className={cx('visibleSection')}>
          <div
            className={cx('itemDate', expanded && 'down')}
            onClick={() => setExpanded(!expanded)}
            role="button"
            tabIndex={0}
          >
            {moment(date).utc().format('MM/DD/YY')}
          </div>
          <div className={cx('options')}>
            {typeof onSelect === 'function' && (
              <Checkbox label="Select" value={!!selected} onChange={onSelect} />
            )}
            {onEdit && (
              <Button white className={cx('editButton')} onClick={() => toggleEdit(true)}>
                <img src={edit} alt="edit" />
              </Button>
            )}
            {typeof onDelete === 'function' && (
              <>
                {confirmDelete ? (
                  <ConfirmButton
                    key={_id}
                    white
                    className={cx('deleteButton')}
                    onClick={e => {
                      e.preventDefault();
                      onDelete();
                    }}
                    confirmingProps={{ danger: true }}
                    confirmText="Confirm Delete"
                  >
                    <img src={trash} alt="delete" />
                  </ConfirmButton>
                ) : (
                  <Button white className={cx('deleteButton')} onClick={onDelete}>
                    <img src={trash} alt="delete" />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {expanded && (
          <div
            className={cx(
              'hiddenSection',
              'container',
              expanded && 'expanded',
              !expanded && 'collapsed'
            )}
          >
            <div className={cx('hiddenRow')}>
              <div>
                <label>Times</label>
                <div>
                  {moment.tz(startTime, 'HH:mm', timezone).format('h:mm A')} to{' '}
                  {moment.tz(endTime, 'HH:mm', timezone).format('h:mm A (zz)')}
                </div>
              </div>
            </div>
            <div className={cx('hiddenRow')}>
              <div>
                <label>Cost</label>
                <div>{formatCents(centsDaily)} per day</div>
                <div>{formatCents(centsHourly)} per hour</div>
              </div>
            </div>
            <div className={cx('hiddenRow')}>
              <div>
                <label>Sublocations</label>
                <div className={cx('note')}>
                  {sublocations.length > 0
                    ? _.map(
                        sublocations,
                        (s, i) => `${i === sublocations.length - 1 ? s : `${s}, `}`
                      )
                    : 'This permit applies to the entire venue'}
                </div>
              </div>
            </div>
            {!hideLeagues && !!_.size(leagues) && (
              <div className={cx('hiddenRow')}>
                <div>
                  <label>Leagues</label>
                  <div className={cx('note')}>
                    {_.map(leagues, ({ _id: leagueId, name }) => (
                      <li key={leagueId}>
                        <Link
                          to={`/rfo/league/${leagueId}/view?organization=${organizationId}`}
                          target="_blank"
                        >
                          {name}
                        </Link>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className={cx('hiddenRow')}>
              {note && (
                <div>
                  <label>Note</label>
                  <div className={cx('note')}>{note}</div>
                </div>
              )}
            </div>
            <div className={cx('hiddenRow', 'justify-content-end')}>
              Reference ID: {referenceId || 'N/A'}
            </div>
            {_id && <div className={cx('hiddenRow', 'justify-content-end')}>Permit ID: {_id}</div>}
          </div>
        )}
      </div>
      <Popup className="middle" hidden={!showEdit} close={() => toggleEdit(false)}>
        <EditPermit permitId={permit._id} />
      </Popup>
    </>
  );
};

ViewPermit.propTypes = {
  permit: PropTypes.shape({
    _id: PropTypes.string,
    centsHourly: PropTypes.number,
    note: PropTypes.string,
    centsDaily: PropTypes.number,
    referenceId: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    sublocations: PropTypes.arrayOf(PropTypes.string),
    leagues: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  organizationId: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  confirmDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  hideLeagues: PropTypes.bool,
};

ViewPermit.defaultProps = {
  confirmDelete: false,
  onSelect: null,
  selected: false,
  onDelete: null,
  onEdit: false,
  hideLeagues: false,
};

export default withCurrentUser()(ViewPermit);
