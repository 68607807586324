import { Heading, Modal, Skeleton, Text, View } from '@rivallapp/volosports-components';
import classNames from 'classnames/bind';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useMutation, type TypedDocumentNode, gql, useSuspenseQuery } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';
import styles from '../../styles/main.module.scss';
import type { PaymentSource } from '../../features/PaymentForm/EditPaymentMethods/EditPaymentMethods';
import { ADD_PAYMENT_SOURCE } from '../../apps/APP/Register/REGISTER_QUERIES_AND_MUTATIONS';
import { useAlertMessage } from '../../hooks';
import PaymentSources from '../../apps/APP/Register/RegisterSections/04-Payment/PaymentSources';
import StripeForm from '../../apps/APP/Register/RegisterSections/04-Payment/StripeForm';

const cx = classNames.bind(styles);

type CurrentUserPaymentSourcesData = {
  currentUser: {
    _id: string;
    paymentSources: {
      id: string;
      last4: string;
      isDefault: boolean;
      isExpired: boolean;
      brand: string;
      exp_month: number;
      exp_year: number;
    }[];
  };
};

export const CURRENT_USER_DATA: TypedDocumentNode<CurrentUserPaymentSourcesData> = gql`
  query currentUserPaymentSources {
    currentUser {
      _id
      paymentSources {
        last4
        isDefault
        isExpired
        brand
        exp_month
        exp_year
        id
      }
    }
  }
`;

type Props = {
  /**
   * Optional callback for when the payment source changes.
   * If provided, triggers a useEffect to invoke this right away.
   */
  onSourceChange?: (source: PaymentSource | null) => void;
};

/**
 * Payment card modal based off of `AddPaymentCard` with all the RegistrationContext
 * logic stripped out so that we can use this anywhere.
 */
const AddPaymentCardModal = ({ onSourceChange }: Props) => {
  const [showPayment, setShowPayment] = useState(false);

  const { data } = useSuspenseQuery(CURRENT_USER_DATA);

  const { paymentSources = [] } = data.currentUser || {};

  const defaultPaymentSource = paymentSources.find(p => p.isDefault && !p.isExpired);

  const paymentText = defaultPaymentSource?.last4
    ? `We'll charge the card ending in ${defaultPaymentSource.last4}.`
    : 'No card on file.';

  const [addPaymentMutation] = useMutation(ADD_PAYMENT_SOURCE);
  const { showSuccess, showError } = useAlertMessage();

  const onTokenSuccess = useCallback(
    async ({ token }: { token: any }) => {
      try {
        await addPaymentMutation({
          variables: { input: { tokenId: token?.id } },
        });
        showSuccess('Successfully added payment method');
      } catch (error) {
        showError(error);
      }
    },
    [addPaymentMutation, showError, showSuccess]
  );

  useEffect(
    () => onSourceChange?.(defaultPaymentSource || null),
    [defaultPaymentSource, onSourceChange]
  );

  return (
    <>
      <Text fontSize="sm">
        {paymentText}{' '}
        <Text fontSize="sm" isLink onPress={() => setShowPayment(true)}>
          Edit Payment
        </Text>
      </Text>
      <Modal useRNModal size="lg" isOpen={showPayment} onClose={() => setShowPayment(false)}>
        <Modal.Content>
          <Modal.Header>
            Edit Payment
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <View>
              {!!paymentSources?.length &&
                paymentSources?.map(source => <PaymentSources key={source?.id} source={source} />)}
              <Heading type="subsection" fontSize="md">
                Add a Card
              </Heading>

              {/* quite a few styles here that would be difficult to replicate using NB */}
              <div className={cx('register-text-field', 'pt-3')}>
                <StripeForm onTokenSuccess={onTokenSuccess} />
              </div>
            </View>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
};

const Loading = () => <Skeleton.Text lines={1} width="50%" />;
const Error = () => (
  <Text fontSize="sm" color="error.500">
    Something went wrong while fetching card information.
  </Text>
);

export default (props: Props) => (
  <Suspense fallback={<Loading />}>
    <ErrorBoundary FallbackComponent={Error}>
      <AddPaymentCardModal {...props} />
    </ErrorBoundary>
  </Suspense>
);
