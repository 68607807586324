// eslint-disable-next-line import/prefer-default-export
export const RoleEnum = {
  ADMIN: 'ADMIN', // super admin
  OWNER: 'OWNER', // city admin
  STAFF: 'STAFF', // host
  PLAYER: 'PLAYER', // player
  MEMBER: 'MEMBER', // VoloPass member
} as const;

export type RoleEnum = (typeof RoleEnum)[keyof typeof RoleEnum];
