import { Suspense, useState, type FC } from 'react';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { IconButton, FontAwesomeIcon, Modal, Text, View } from '@rivallapp/volosports-components';
import { faEnvelopeDot } from '@fortawesome/pro-solid-svg-icons/faEnvelopeDot';
import type { ResultOf } from 'graphql-schema';
import { Button, GenericErrorBoundary, Loading, PrintWaiverIconButton } from '../../../components';
import {
  INSERT_REGISTRANT_WAIVER_REMINDER,
  INSERT_RENTAL_REGISTRANT_WAIVER_REMINDER,
} from '../PLAYER_DETAILS_MUTATIONS';
import { WAIVER_QUERY } from '../PLAYER_DETAILS_QUERIES';
import { useAlertMessage } from '../../../hooks';
import hasuraClient from '../../../apollo/hasuraClient';

const RemindIcon = <FontAwesomeIcon icon={faEnvelopeDot} />;

type WaiverReminders = NonNullable<
  ResultOf<typeof INSERT_REGISTRANT_WAIVER_REMINDER>['insert_registrant_waiver_reminders_one']
>['registrantByRegistrant']['waiver_reminders'];

type Props = {
  waiverId: string;
  registrantId: string;
  isRental?: boolean;
  refetch?: () => Promise<any>;
  dateSigned?: Date | string | null;
  userFullName?: string | null;
  waiverReminders?: WaiverReminders;
};

const WaiverAction: FC<Props> = ({
  registrantId,
  waiverId,
  dateSigned,
  isRental,
  refetch,
  userFullName,
  waiverReminders,
}) => {
  const [hidePopup, setHidePopup] = useState(true);
  const { showError, showSuccess } = useAlertMessage();

  const [sendReminderMutation] = useMutation(INSERT_REGISTRANT_WAIVER_REMINDER);
  const [sendReminderRentalMutation] = useMutation(INSERT_RENTAL_REGISTRANT_WAIVER_REMINDER);

  const { data } = useSuspenseQuery(WAIVER_QUERY, {
    client: hasuraClient,
    variables: { _id: waiverId },
  });

  const sendReminder = async () => {
    try {
      if (isRental) {
        await sendReminderRentalMutation({
          client: hasuraClient,
          variables: { registrant: registrantId },
        });
      } else {
        await sendReminderMutation({
          client: hasuraClient,
          variables: { registrant: registrantId },
        });
      }
      showSuccess('Reminder email sent!');
      if (refetch) await refetch();
      setHidePopup(true);
    } catch (e) {
      showError(e);
    }
  };

  const { name } = data?.waivers_by_pk ?? {};

  return (
    <>
      <Modal useRNModal size="lg" isOpen={!hidePopup} onClose={() => setHidePopup(true)}>
        <Modal.Content>
          <Modal.Header>
            Please confirm action
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Text fontSize="sm">
              Are you sure you want to send a reminder email to{' '}
              <Text fontSize="sm" bold>
                {userFullName}
              </Text>{' '}
              for signing{' '}
              <Text fontSize="sm" bold>
                {name}
              </Text>
              ?
            </Text>
            <View borderRadius={1} py={4} my={6} bgColor="white.800" textAlign="center">
              <Text bold fontSize="sm">
                Reminders sent previously ({waiverReminders?.length})
              </Text>
              {waiverReminders?.map(reminder => {
                const { _id, date_sent, userByCreatedBy } = reminder;
                return (
                  <Text key={_id} fontSize="sm">
                    {new Date(date_sent).toLocaleDateString()} by {userByCreatedBy.username}
                  </Text>
                );
              })}
            </View>
          </Modal.Body>

          <Modal.Footer>
            <Button className="mx-2" small grey onClick={() => setHidePopup(true)}>
              Cancel
            </Button>
            <Button className="mx-2" small action onClick={sendReminder}>
              Send
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      {dateSigned ? (
        <PrintWaiverIconButton
          size="sm"
          backgroundColor="transparent"
          waiverId={waiverId}
          dateSigned={dateSigned}
          fullName={userFullName}
        />
      ) : (
        <IconButton
          size="sm"
          variant="ghost"
          icon={RemindIcon}
          backgroundColor="transparent"
          onPress={() => setHidePopup(false)}
        />
      )}
    </>
  );
};

export default (props: Props) => (
  <GenericErrorBoundary>
    <Suspense fallback={<Loading type="inline" />}>
      <WaiverAction {...props} />
    </Suspense>
  </GenericErrorBoundary>
);
