import loadable from '@loadable/component';
import { Redirect, Route, Switch } from 'react-router-dom';
import qs from 'qs';
import AuthenticatedRoute from './AuthenticatedRoute';
import { PageLoader } from '../components/Loading/Loading';
import LeagueRoute, { RedirectToStepRoute } from './LeagueRoute';
import PlayerController from './PlayerController';
import PublicRoutes from './PublicController';
import RfoController from './RfoController';
import StaffController from './StaffController';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import { dashSpaceSwap } from '../utils/url-search-helpers';
import RoleEnum from '../shared/role-enum';
import { StaticUrls } from '../shared/static-urls-enum';
import RentalSportRedirect from './redirects/RentalSportRedirect';
import RentalVenueRedirect from './redirects/RentalVenueRedirect';
import useOrganizations from '../hooks/useOrganizations';

const AboutUs = loadable(() => import('../apps/Anon/HomepageV2/AboutUs'), {
  fallback: <PageLoader />,
});
const CityRules = loadable(() => import('../apps/Anon/HomepageV2/CityRules/CityRules'), {
  fallback: <PageLoader />,
});
const ContactUs = loadable(() => import('../apps/Anon/HomepageV2/ContactUs'), {
  fallback: <PageLoader />,
});
const Corporate = loadable(() => import('../apps/Anon/HomepageV2/Corporate'), {
  fallback: <PageLoader />,
});
const CorporateV2 = loadable(() => import('../apps/Anon/HomepageV2/CorporateV2/CorporateV2'), {
  fallback: <PageLoader />,
});
const FAQ = loadable(() => import('../apps/Anon/HomepageV2/FAQ'), { fallback: <PageLoader /> });
const GameShow = loadable(() => import('../apps/Anon/HomepageV2/GameShow'), {
  fallback: <PageLoader />,
});
const HomepageV2 = loadable(() => import('../apps/Anon/HomepageV2/HomePage'), {
  fallback: <PageLoader />,
});
const Legal = loadable(() => import('../apps/Anon/HomepageV2/Legal'), { fallback: <PageLoader /> });
const LoginPage = loadable(() => import('../apps/Anon/Login'), { fallback: <PageLoader /> });
const Policies = loadable(() => import('../apps/Anon/HomepageV2/Policies'), {
  fallback: <PageLoader />,
});
const Rules = loadable(() => import('../apps/Anon/HomepageV2/NationalRules'), {
  fallback: <PageLoader />,
});
const SportPage = loadable(() => import('../apps/Anon/HomepageV2/SportPage/SportPage'), {
  fallback: <PageLoader />,
});
const Trivia = loadable(() => import('../apps/Anon/HomepageV2/Trivia'), {
  fallback: <PageLoader />,
});
const Virtual = loadable(() => import('../apps/Anon/HomepageV2/Virtual'), {
  fallback: <PageLoader />,
});
const VoloPass = loadable(() => import('../apps/Anon/HomepageV2/VoloPassV2/VoloPassV2'), {
  fallback: <PageLoader />,
});
const Register = loadable(() => import('../apps/APP/Register'), { fallback: <PageLoader /> });
const RegisterDaily = loadable(() => import('../apps/APP/RegisterDaily'), {
  fallback: <PageLoader />,
});
const RentalDetails = loadable(() => import('../apps/APP/RentalDetails'), {
  fallback: <PageLoader />,
});
const LeagueOverview = loadable(() => import('../apps/APP/LeagueOverview'), {
  fallback: <PageLoader />,
});
const Onboarding = loadable(() => import('../apps/Onboarding'), { fallback: <PageLoader /> });
const OnboardingV2 = loadable(() => import('../apps/OnboardingV2'), { fallback: <PageLoader /> });
const ErrorPage = loadable(() => import('../pages/ErrorPage'), { fallback: <PageLoader /> });

const NYC_REDIRECTS = [
  <Redirect
    key="ny-redirect-vp"
    exact
    from="/New-York/volo-pass"
    to="/New-York-Metro-Area/volo-pass"
  />,
  <Redirect
    key="ny-redirect-corporate"
    exact
    from="/New-York/corporate"
    to="/New-York-Metro-Area/corporate"
  />,
  <Redirect key="ny-redirect-base" exact from="/New-York" to="/New-York-Metro-Area" />,
  <Redirect key="ny-redirect-rules" exact from="/New-York/rules" to="/New-York-Metro-Area/rules" />,
  <Redirect
    key="ny-redirect-sport"
    from="/New-York/:sportName"
    to="/New-York-Metro-Area/:sportName"
  />,
];
const NJ_REDIRECTS = [
  <Redirect key="nj-redirect-vp" exact from="/North-Jersey/volo-pass" to="/Morristown/volo-pass" />,
  <Redirect
    key="nj-redirect-corporate"
    exact
    from="/North-Jersey/corporate"
    to="/Morristown/volo-pass"
  />,
  <Redirect key="nj-redirect-base" exact from="/North-Jersey" to="/Morristown" />,
  <Redirect key="nj-redirect-rules" exact from="/North-Jersey/rules" to="/Morristown/rules" />,
  <Redirect key="nj-redirect-sport" from="/North-Jersey/:sportName" to="/Morristown/:sportName" />,
];

// TODO - This could use further cleanup but will save that for the react-router-dom upgrade ticket
const Controller = () => {
  const { organizations } = useOrganizations();
  const cityPaths = organizations.map(({ name }) => dashSpaceSwap(name, 'toDash'));

  return (
    <Switch>
      <Route exact path="/" component={HomepageV2} />
      <Route exact path="/Virtual/Trivia" component={Trivia} />
      <Route exact path="/Virtual/Game-Show" component={GameShow} />
      <Route exact path="/volo-pass" component={VoloPass} />
      {NYC_REDIRECTS}
      {NJ_REDIRECTS}
      <Route exact path="/:city/volo-pass" component={VoloPass} />
      <Route exact path="/:city/corporate" component={CorporateV2} />
      <Route exact path="/:cityName/rules" component={CityRules} />,
      {cityPaths.flatMap(path => [
        <Route
          key={path}
          exact
          path={`/${path}`}
          component={path === 'Virtual' ? Virtual : HomepageV2}
        />,
        <Route key={`sp${path}`} path={`/${path}/:sportName`} component={SportPage} />,
      ])}
      <Redirect exact from="/volopass-registration" to="/app/dashboard?show=volo-pass" />
      <Route exact path="/rules" component={Rules} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/corporate" component={Corporate} />
      <Redirect exact from="/covid" to="/policies" />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/policies" component={Policies} />
      <Route exact path="/legal" component={Legal} />
      <Route path="/trivia" render={() => <Redirect to="/Virtual/trivia" />} />
      <Route path="/careers" render={() => <Redirect to={StaticUrls.CAREERS} />} />
      <Route path="/athletes" render={() => <Redirect to={StaticUrls.ATHLETES} />} />
      <RedirectToStepRoute exact path="/app/register/:id" />
      {/* used https://github.com/remix-run/react-router/issues/5818#issuecomment-379212014 as a reference */}
      <Route
        path="/discover/league-landing/"
        // eslint-disable-next-line react/no-unstable-nested-components
        component={({ location }) => {
          const search = qs.parse(location.search);
          const programId = search['?programId'];
          if (!programId) {
            return <Route component={ErrorPage} />;
          }
          return (
            <Redirect
              to={{
                pathname: `/l/${programId}`,
              }}
            />
          );
        }}
      />
      <Route path="/l/:leagueId" component={LeagueOverview} />
      <Route
        path="/discover/league-registration/"
        // eslint-disable-next-line react/no-unstable-nested-components
        component={({ location }) => {
          const search = qs.parse(location.search);
          const joiningGroupId = search['?joiningGroupId'];
          if (!joiningGroupId) {
            return <Route component={ErrorPage} />;
          }
          return (
            <Redirect
              to={{
                pathname: `/g/${joiningGroupId}`,
              }}
            />
          );
        }}
      />
      <Route path="/g/:groupId" component={LeagueOverview} />
      <LeagueRoute path="/app/register/:leagueId/:step" component={Register} />
      {/* Routes for unauthenticated users only */}
      <UnauthenticatedRoute path="/signup/app" render={() => <Redirect to="/signup/start" />} />
      <UnauthenticatedRoute path="/signup" component={Onboarding} redirect="/app/dashboard" />
      {/* TODO [MIGRATION]: Remove v2 routes and replace v1 */}
      <UnauthenticatedRoute path="/signupV2/app" render={() => <Redirect to="/signupV2/start" />} />
      <UnauthenticatedRoute path="/signupV2" component={OnboardingV2} redirect="/app/dashboard" />
      <UnauthenticatedRoute exact path="/login" component={LoginPage} redirect="/app/dashboard" />
      {/* drop-in routes */}
      <Route
        path="/discover/daily-landing/"
        // eslint-disable-next-line react/no-unstable-nested-components
        component={({ location }) => {
          const search = qs.parse(location.search);
          const programId = search['?programId'];
          const gameId = search['?gameId'];
          if (!programId && !gameId) {
            return <Route component={ErrorPage} />;
          }
          return (
            <Redirect
              to={{
                pathname: programId ? `/d/${programId}` : `/game/${search['?gameId']}`,
              }}
            />
          );
        }}
      />
      {/* daily program route */}
      <Route path="/d/:leagueId/" component={RegisterDaily} />
      <Route path="/game/:gameId/" component={RegisterDaily} />
      <Route
        path="/discover/daily-registration/"
        // eslint-disable-next-line react/no-unstable-nested-components
        component={({ location }) => {
          const search = qs.parse(location.search);
          const slotId = search['?slotId'];
          if (!slotId) {
            return <Route component={ErrorPage} />;
          }
          return (
            <Redirect
              to={{
                pathname: `/drop-in/${search['?slotId']}`,
              }}
            />
          );
        }}
      />
      <Route path="/drop-in/:dropInId/" component={RegisterDaily} />
      {/* rentals routes */}
      <Route path="/r/:_id" component={RentalDetails} />
      <Route path="/discover/rentals/sport-view/" component={RentalSportRedirect} />
      <Route path="/discover/rentals/venue-view/" component={RentalVenueRedirect} />
      <AuthenticatedRoute path="/app" role={RoleEnum.PLAYER} component={PlayerController} />
      <AuthenticatedRoute path="/rfo" role={RoleEnum.OWNER} component={RfoController} />
      <AuthenticatedRoute path="/staff" role={RoleEnum.STAFF} component={StaffController} />
      <PublicRoutes />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default Controller;
