import { useMemo } from 'react';
import { gql, type QueryHookOptions, type TypedDocumentNode, useQuery } from '@apollo/client';
import { GenderNameEnum } from '../constants';

interface NotificationPreferences {
  email: boolean;
  push: boolean;
  sms: boolean;
}

export interface CurrentUserResponse {
  currentUser: {
    _id: string;
    username: string;
    homeOrganization: string;
    shouldSeePricesWithFees: boolean;
    firstName: string;
    lastName: string;
    picture: string;
    gender: keyof typeof GenderNameEnum;
    shirt_size?: string;
    paymentSources: {
      last4: number;
      isDefault: boolean;
      isExpired: boolean;
      exp_month: number;
      exp_year: number;
      brand: string;
      id: string;
    }[];
    availableCredits: {
      organizationId: string;
      amount: number;
      used: number;
    }[];
    email: string;
    notificationPreferences: {
      marketing: NotificationPreferences;
      team: NotificationPreferences;
    };
  };
}

/** copied from mobile to give us a starting place
 * feel free to modify as use cases emerge
 */

// @techdebt split up into smaller queries for performance
// - allLeagues is from `useCurrentUserAllLeagues`
// - remove payment sources from here and utilize the already `usePaymentSources` hook
// - remove available credits and create new hook `useAvailableCredits`
export const GET_CURRENT_USER: TypedDocumentNode<CurrentUserResponse> = gql`
  query currentUser {
    currentUser {
      _id
      username
      homeOrganization
      shouldSeePricesWithFees
      lastName
      firstName
      picture
      gender
      shirt_size
      paymentSources {
        last4
        isDefault
        isExpired
        exp_month
        exp_year
        brand
        id
      }
      availableCredits {
        organizationId
        amount
        used
      }
      email
      notificationPreferences {
        marketing {
          email
          push
          sms
        }
        team {
          email
          push
          sms
        }
      }
    }
  }
`;

/**
 * useCurrentUser is a wrapper hook around `useQuery` that handles getting the current user
 * @example
 * const { currentUser, error } = useCurrentUser();
 * @deprecated use `useCurrentUserV2` instead
 */
const useCurrentUser = (options?: QueryHookOptions<CurrentUserResponse>) => {
  const { data, ...rest } = useQuery(GET_CURRENT_USER, {
    nextFetchPolicy: 'cache-first',
    ...options,
  });

  const currentUser = useMemo(
    () => ({
      ...data?.currentUser,
      notificationPreferences: {
        push: data?.currentUser?.notificationPreferences?.marketing?.push,
        email: data?.currentUser?.notificationPreferences?.marketing?.email,
        sms: data?.currentUser?.notificationPreferences?.marketing?.sms,
      },
    }),
    [data?.currentUser]
  );

  return {
    ...rest,
    currentUser,
  };
};

export default useCurrentUser;
