import { configureClient } from '@rivallapp/apollo-client';
import useAuthStore from '../zustand-stores/AuthStore/AuthStore';
import { hasuraURL } from '../environment';
import errorLogLink from './links/createErrorLogLink';

if (!hasuraURL) throw new Error('Missing hasuraURL in environment.');

const hasuraClient = configureClient(
  {
    getAuthToken: () => {
      // TODO [MIGRATION]: Clean up this logic once fully migrated to Hasura
      const { authToken, isAuthenticatedWithHasura } = useAuthStore.getState();
      return isAuthenticatedWithHasura ? `Bearer ${authToken}` : authToken;
    },
    getRoles: () => useAuthStore.getState()?.roles,
    // Should be using your dev-cloud as set by `inject-hasura-endpoint.sh` script
    graphqlURL: hasuraURL,
  },
  {
    link: errorLogLink,
  }
);

export default hasuraClient;
