import { useSuspenseQuery } from '@apollo/client';
import type { ComponentProps, FC } from 'react';
import type { IBoxProps } from '@rivallapp/native-base';
import { Box, Heading } from '../../../base-components';
import { Table } from '../../../custom-components';
import { WholeDollarFormatter } from '../../../utilities';
import { RENTAL_DETAILS } from '../gql';

const PricingHeader = ({
  value,
  headerProps,
}: {
  value?: string;
  headerProps?: ComponentProps<typeof Table.Header>;
}) => (
  <Table.Header
    borderColor="transparent"
    textProps={{ fontWeight: 'normal', fontSize: 'md' }}
    {...headerProps}
  >
    {value}
  </Table.Header>
);

interface ProgramPricingProps {
  rental_id: string;
  _container?: IBoxProps;
}

const ProgramPricing: FC<ProgramPricingProps> = ({ rental_id, _container }) => {
  const { data } = useSuspenseQuery(RENTAL_DETAILS, {
    variables: {
      id: rental_id,
    },
  });

  const { standard_price_cents, vp_standard_price_cents, prime_price_cents, vp_prime_price_cents } =
    data.rentals_by_pk!;

  return (
    <Box bg="white.600" borderRadius="3" p="4" {..._container}>
      <Heading fontSize="lg" fontWeight="normal">
        Pricing Information
      </Heading>
      <Table>
        <Table.Row>
          <PricingHeader />
          <PricingHeader value="Pricing" />
          <PricingHeader value="Volo Pass Pricing" />
        </Table.Row>
        {/* TODO (pending product decision) how these should be styled if no prime pricing */}
        <Table.Row>
          <Table.Cell borderTopLeftRadius="5">Standard Pricing</Table.Cell>
          <Table.Cell>{WholeDollarFormatter.format(standard_price_cents / 100)}/hr</Table.Cell>
          <Table.Cell borderTopRightRadius="5">
            {WholeDollarFormatter.format(vp_standard_price_cents / 100)}/hr
          </Table.Cell>
        </Table.Row>
        {typeof prime_price_cents === 'number' && typeof vp_prime_price_cents === 'number' && (
          <Table.Row>
            <Table.Cell borderBottomLeftRadius="5">Prime Time Pricing</Table.Cell>
            <Table.Cell>{WholeDollarFormatter.format(prime_price_cents / 100)}/hr</Table.Cell>
            <Table.Cell borderBottomRightRadius="5">
              {WholeDollarFormatter.format(vp_prime_price_cents / 100)}/hr
            </Table.Cell>
          </Table.Row>
        )}
      </Table>
    </Box>
  );
};

export default ProgramPricing;
