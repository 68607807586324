import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { dashSpaceSwap } from '../../../../../utils/url-search-helpers';
import DropdownLinkList from '../components/DropdownLinkList';
import styles from '../../../../../styles/main.module.scss';
import { Button, LinkToRentals } from '../../../../../components';
import { chunkWithBreakpoint } from '../../../../../shared/array-helpers';
import type { LinkItem } from '../components/DropdownLinkList';

const cx = classNames.bind(styles);

type PlayVoloProps = {
  cityOptions: { label: string; value: string }[];
  hideNav: boolean;
  cityName: string;
  organizationSports: { sportName: string }[];
};

const PlayVolo: React.FC<PlayVoloProps> = ({
  cityOptions,
  hideNav,
  cityName,
  organizationSports,
}) => {
  const adultLeaguesLinks: LinkItem[] = cityOptions.map(({ label }) => ({
    name: label,
    href: `/${dashSpaceSwap(label, 'toDash')}`,
  }));

  return (
    <div className={cx(hideNav && 'small-hide-col', 'col-12', 'col-md-auto', 'my-auto')}>
      <div className={cx('dropdownButton', 'navCol')}>
        Play Volo
        <div className={cx('dropdown')}>
          <div className="row">
            <div className="col-12 col-md-auto">
              {/*
                If on a city page, show them the organizations, otherwise, show
                them the sport in their selected organization.
              */}
              {cityName ? (
                <>
                  <h4>Discover Programs</h4>
                  <div className="row">
                    {chunkWithBreakpoint(organizationSports).map(batch => (
                      <div className="col" key={batch.map(b => b.sportName).join(',')}>
                        <ul className={cx('innerList')}>
                          {batch.map(({ sportName }) => (
                            <li key={sportName}>
                              <Link
                                to={{
                                  pathname: '/discover',
                                  search: `?cityName=${cityName}&sportNames[]=${sportName}`,
                                }}
                              >
                                {sportName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                  <LinkToRentals cityName={cityName}>
                    <Button skyblue className="mt-3">
                      NEW - COURT RENTALS
                    </Button>
                  </LinkToRentals>
                </>
              ) : (
                <DropdownLinkList heading="Adult Leagues" links={adultLeaguesLinks} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayVolo;
