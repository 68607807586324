/* eslint-disable react/no-danger */
import { useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLazyQuery } from '@apollo/client';
import { sanitize } from 'isomorphic-dompurify';
import { graphql } from 'graphql-schema';
import moment from 'moment-timezone';

export const GET_WAIVER = graphql(`
  query Waiver($waiverId: uuid!) {
    waivers_by_pk(_id: $waiverId) {
      _id
      content
    }
  }
`);

export type UsePrintWaiverArgs = {
  waiverId: string | null | undefined;
  fullName?: string | null | undefined;
  dateSigned?: Date | null | undefined;
  setError?: (value: string | null | undefined) => void;
};

/**
 * Hooks that contains all logic necessary to print a league/signed waiver.
 *
 * If `fullName` and `dateSigned` are provided, the waiver will be printed with a
 * signature section.
 *
 * @info WEB ONLY as of 4/3/24
 *
 * @returns tuple that contains:
 *  - The printable DOM node
 *  - A callback that triggers the print view
 *  - A boolean that indicates if the document/query is loading
 *  - The result of the waiver query
 */
const usePrintWaiver = ({ waiverId, fullName, dateSigned, setError }: UsePrintWaiverArgs) => {
  const [contentLoading, setContentLoading] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const [getWaiver, query] = useLazyQuery(GET_WAIVER, {
    fetchPolicy: 'network-only',
  });

  // A slightly more complex setup than usual since we have to manually inject HTML
  const print = useReactToPrint({
    content: () => ref.current,
    removeAfterPrint: true,
    onBeforeGetContent: () => setContentLoading(true),
    print: async iframe => {
      // This needs to be set to false first else the the state change
      // will not register until after the print preview is closed.
      setContentLoading(false);

      try {
        if (!waiverId) throw new Error();
        const { data } = await getWaiver({
          variables: { waiverId },
        });
        // locate the div element with the waiver contents
        const elem = iframe.contentDocument?.querySelector(
          `#waiver-content-${waiverId}`
        ) as HTMLElement;
        // Set the waiver contents
        elem.innerHTML = sanitize(data?.waivers_by_pk?.content ?? '');
        // Display the parent elem
        elem.parentElement!.style.display = 'block';
        // Remove the froala watermark
        elem.querySelector('p[data-f-id="pbf"]')?.remove();
        // Display the print preview
        iframe.contentWindow?.print();
      } catch (err) {
        if (setError) {
          setError('There was an error loading this waiver');
        }
      }
    },
  });

  const DOM_NODE = useMemo(
    () => (
      <div ref={ref} aria-hidden style={{ display: 'none', padding: '2em' }}>
        <div id={`waiver-content-${waiverId}`} />
        {!!(fullName && dateSigned) && (
          <div className="mt-5">
            <p>
              Date signed: <strong>{moment(dateSigned).format('MM/DD/YYYY [at] h:mma')}</strong>
            </p>
            <p className="mt-1">
              Signed by: <strong>{fullName}</strong>
            </p>
          </div>
        )}
      </div>
    ),
    [dateSigned, fullName, waiverId]
  );

  const loading = contentLoading || query.loading;

  return [DOM_NODE, print, loading, query] as const;
};

export default usePrintWaiver;
