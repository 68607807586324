import { graphql } from 'graphql-schema';

export const RENTAL_DETAILS = graphql(`
  query RentalDetails($id: uuid!) {
    rentals_by_pk(_id: $id) {
      _id
      name
      has_numeric_courts
      is_discoverable
      banner_message
      standard_price_cents
      prime_price_cents
      vp_standard_price_cents
      vp_prime_price_cents
      featured_order
      deep_link
      courts {
        _id
        name
      }
      sportBySport {
        _id
        name
        surface_type
      }
      venueByVenue {
        _id
        place_id
        timezone
        shorthand_name
        formatted_address
      }
      waiverByWaiver {
        _id
        content
      }
      first_timeslot_by_date: timeslots(order_by: { start_time: asc_nulls_last }, limit: 1) {
        _id
        start_time
      }
      last_timeslot_by_date: timeslots(order_by: { start_time: desc_nulls_last }, limit: 1) {
        _id
        end_time
      }
      first_timeslot_by_time: timeslots(order_by: { start_time_str: asc_nulls_last }, limit: 1) {
        _id
        start_time_str
      }
      last_timeslot_by_time: timeslots(order_by: { start_time_str: desc_nulls_last }, limit: 1) {
        _id
        end_time_str
      }
      has_available_timeslots: timeslots_aggregate(
        where: { start_time: { _gt: "now()" }, reservation: { _is_null: true } }
        limit: 1
      ) {
        aggregate {
          count
        }
      }
    }
  }
`);

export const RENTAL_OVERVIEW_STATS = graphql(`
  query RentalOverviewStats($_id: uuid!) {
    rentals_by_pk(_id: $_id) {
      _id
      future_revenue_cents
      slots_available: timeslots_aggregate(
        where: { reservation: { _is_null: true }, start_time: { _gte: "now()" } }
      ) {
        aggregate {
          count
        }
      }
      slots_taken: timeslots_aggregate(
        where: { reservation: { _is_null: false }, start_time: { _gte: "now()" } }
      ) {
        aggregate {
          count
        }
      }
      reservation_holders: reservations_aggregate(where: { start_time: { _gte: "now()" } }) {
        aggregate {
          count
        }
      }
      attendee_count: registrants_aggregate(
        where: { rental_reservation: { start_time: { _gte: "now()" } } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`);

export const UPDATE_RENTAL_DISCOVERABILITY = graphql(`
  mutation UpdateRentalDiscoverability($_id: uuid!, $is_discoverable: Boolean!) {
    update_rentals_by_pk(pk_columns: { _id: $_id }, _set: { is_discoverable: $is_discoverable }) {
      _id
      is_discoverable
    }
  }
`);

export const UPDATE_RENTAL_BANNER_MESSAGE = graphql(`
  mutation UpdateRentalBannerMessage($_id: uuid!, $banner_message: String!) {
    update_rentals_by_pk(pk_columns: { _id: $_id }, _set: { banner_message: $banner_message }) {
      _id
      banner_message
    }
  }
`);

export const UPDATE_RENTAL_FEATURED_ORDER = graphql(`
  mutation UpdateRentalFeaturedOrder($_id: uuid!, $featured_order: Int) {
    update_rentals_by_pk(pk_columns: { _id: $_id }, _set: { featured_order: $featured_order }) {
      _id
      featured_order
    }
  }
`);
