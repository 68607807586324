import { gql } from '@apollo/client';
import { graphql } from 'graphql-schema';

export const EMAIL_IS_VALID = gql`
  mutation emailIsValid($input: EmailIsValidInput!) {
    emailIsValid(input: $input) {
      isValid
      value
      message
    }
  }
`;

export const PHONE_NUMBER_IS_VALID = gql`
  mutation phoneNumberIsValid($input: PhoneNumberIsValidInput!) {
    phoneNumberIsValid(input: $input) {
      isValid
      value
      message
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserData($input: UpdateUserDataInput!) {
    updateUserData(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const UPDATE_USER_HOME_ORG = gql`
  mutation updateUserHomeOrganization($input: UpdateUserHomeOrganizationInput!) {
    updateUserHomeOrganization(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const TOGGLE_USER_ACCOUNT_STATUS = gql`
  mutation toggleUserAccountStatus($input: ToggleAccountStatusInput!) {
    toggleUserAccountStatus(input: $input) {
      _id
      deactivated
    }
  }
`;

export const INSERT_REGISTRANT_WAIVER_REMINDER = graphql(`
  mutation InsertRegistrantWaiverReminder($registrant: uuid!) {
    insert_registrant_waiver_reminders_one(object: { registrant: $registrant }) {
      _id
      registrantByRegistrant {
        _id
        waiver_reminders {
          _id
          date_sent
          userByCreatedBy {
            _id
            username
          }
        }
      }
    }
  }
`);

export const INSERT_RENTAL_REGISTRANT_WAIVER_REMINDER = graphql(`
  mutation InsertRentalRegistrantWaiverReminder($registrant: uuid!) {
    insert_rental_registrant_waiver_reminders_one(object: { rental_registrant: $registrant }) {
      _id
      rentalRegistrantByRentalRegistrant {
        _id
        waiver_reminders {
          _id
          date_sent
          userByCreatedBy {
            _id
            username
          }
        }
      }
    }
  }
`);
