const getToolbarButtons = ({ disableMedia }: { disableMedia?: boolean }) => ({
  moreText: {
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'fontFamily',
      'subscript',
      'superscript',
      'fontSize',
      'textColor',
      'backgroundColor',
      'inlineClass',
      'inlineStyle',
      'clearFormatting',
    ],
    buttonsVisible: 5,
  },
  moreParagraph: {
    buttons: [
      'alignLeft',
      'alignCenter',
      'alignRight',
      'alignJustify',
      'formatOLSimple',
      'formatOL',
      'formatUL',
      'paragraphFormat',
      'paragraphStyle',
      'lineHeight',
      'outdent',
      'indent',
      'quote',
    ],
    buttonsVisible: 4,
  },
  moreRich: {
    buttons: [...(!disableMedia ? ['insertImage'] : []), 'insertLink', 'insertTable', 'emoticons'],
    buttonsVisible: 1,
  },
  moreMisc: {
    buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'selectAll', 'html', 'help'],
    align: 'right',
    buttonsVisible: 2,
  },
});

export default getToolbarButtons;
