import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface TechnologyProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  technology_response: boolean;
  technologyComments?: string | null;
  technologyAttachments?: { fileName: string; onPress: () => void }[]; // may change when we add an uploader
}

const Technology: React.FC<TechnologyProps> = ({
  technology_response = false,
  technologyComments,
  technologyAttachments = [],
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Technology"
      view="read"
      toggleQuestion="Any technical issues to report?"
      toggleResponse={technology_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.TextArea
          label="Player or Host App Issues/Notes/Feedback:"
          response={technologyComments ?? undefined}
        />
      </Box>
      {technologyAttachments.length ? (
        <Box paddingTop={5}>
          {technologyAttachments.map(attachment => (
            <CollapseForm.FileUpload
              key={attachment.fileName}
              fileName={attachment.fileName}
              onPress={attachment.onPress}
            />
          ))}
        </Box>
      ) : null}
      {children}
    </CollapseForm>
  );
};

export default Technology;
