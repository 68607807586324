import classNames from 'classnames/bind';
import { Link, type LinkProps } from 'react-router-dom';
import type { ApolloQueryResult } from '@apollo/client';

import { getMediumBlueSportIcon } from '../../../../utils/get-sport-icons';
import star from '../../../../images/icons/gold-star-2.svg';
import styles from '../../player-details.module.scss';
import type {
  RegistrantWaiver,
  UserRegistrantsData,
  UserRegistrantsInput,
} from '../PLAYER_PARTICIPATION_QUERIES';
import type { PlayerParticipationProps } from '../PlayerParticipation';
import { WaiverAction, WaiverStatus } from '../../components';
import { PrintWaiverIconButton } from '../../../../components';

const cx = classNames.bind(styles);

export const ParticipationActivityIcon = ({ activityName }: { activityName?: string }) => {
  if (!activityName) return null;

  return <img src={getMediumBlueSportIcon(activityName)} alt="sport icon" height="35" />;
};

export const ParticipationTeamLink = ({
  teamTo,
  registrantType,
  teamName,
}: {
  teamTo: LinkProps['to'];
  registrantType: string;
  teamName?: string;
}) => {
  return (
    <>
      <b>{registrantType}</b> with <br />
      {teamName ? <Link to={teamTo}>{teamName}</Link> : <p>---</p>}
    </>
  );
};

export const ParticipationRating = () => (
  // 0 for player rating is temporary until we have solution for this on the backend
  <>
    {0}
    <img src={star} className={cx('star')} alt="star" />
  </>
);

export const ParticipationWaiver = ({
  waiver,
  userDetails,
  refetchLeagueList,
  registrantId,
  isRental = false,
}: {
  waiver: RegistrantWaiver;
  userDetails: PlayerParticipationProps['userDetails'];
  refetchLeagueList: (
    variables?: Partial<UserRegistrantsInput> | undefined
  ) => Promise<ApolloQueryResult<UserRegistrantsData>>;
  registrantId: string;
  isRental?: boolean;
}) => {
  return (
    <div className={cx('waiver-controls', 'd-flex justify-content-center align-items-center')}>
      <WaiverStatus signed={!!waiver?.date_signed} />
      {waiver?.date_signed ? (
        <PrintWaiverIconButton
          size="sm"
          backgroundColor="transparent"
          waiverId={waiver?.waiverId}
          dateSigned={waiver.date_signed}
          fullName={`${userDetails.firstName} ${userDetails.lastName}`}
        />
      ) : (
        <WaiverAction
          dateSigned={waiver.date_signed}
          // @ts-expect-error TODO [MIGRATION] - this should be fixed when this is migrated
          waiverReminders={waiver.reminders_sent}
          userFullName={`${userDetails.firstName} ${userDetails.lastName}`}
          waiverId={waiver?.waiverId}
          refetch={refetchLeagueList}
          registrantId={registrantId}
          isRental={isRental}
        />
      )}
    </div>
  );
};
