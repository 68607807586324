import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface EquipmentProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  equipment_response: boolean;
  equipmentIssuesResponses?: string[];
  equipmentComments?: string | null;
  equipmentAttachments?: { fileName: string; onPress: () => void }[]; // may change when we add an uploader
}

const Equipment: React.FC<EquipmentProps> = ({
  equipment_response = false,
  equipmentIssuesResponses = [],
  equipmentComments,
  equipmentAttachments = [],
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Equipment"
      view="read"
      toggleQuestion="Equipment missing, damaged, or lost? Low inventory?"
      toggleResponse={equipment_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.MultiSelect
          label="Equipment Issues:"
          selectedResponses={equipmentIssuesResponses}
        />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea
          label="Please list details about damaged equipment:"
          response={equipmentComments ?? undefined}
        />
      </Box>
      {equipmentAttachments.length ? (
        <Box paddingTop={5}>
          {equipmentAttachments.map(attachment => (
            <CollapseForm.FileUpload
              key={attachment.fileName}
              fileName={attachment.fileName}
              onPress={attachment.onPress}
            />
          ))}
        </Box>
      ) : null}
      {children}
    </CollapseForm>
  );
};

export default Equipment;
