import type { ExtractEnum } from 'graphql-schema';

/**
 * __Note to developer:__
 *
 * If any breaking changes are made to the store structure that would cause
 * a previous stores rehydration to fail, bump this up a version and add a
 * migration function to the persist options in {@link ./AuthStore.ts}
 */
export const AUTH_STORE_VERSION = 3;

export type AuthStore = {
  /** Current logged in userId */
  userId: string | null;
  /** Boolean indicating a user is currently logged in */
  isAuthenticated: boolean;
  /**
   * Boolean indicating a user has signed in through hasura.
   * This is for the migration development only and should be removed once the old login is totally removed.
   * */
  isAuthenticatedWithHasura: boolean;
  /** Current logged in user's roles */
  roles: ExtractEnum<'role_enum'>[];
  /**
   * Stored redirect link for after a user logs in.
   * This is used in the scenario where someone tries to visit a protected
   * page without being logged in. We store the pathname here so that
   * after logging in, we can bring them back to this page/.
   * */
  redirect: string | null;
  /** Auth token for current logged in user - used primarily for WebSocket authentication */
  authToken: string | null;
};

const initialAuthStore: AuthStore = {
  roles: [],
  userId: null,
  redirect: null,
  isAuthenticated: false,
  isAuthenticatedWithHasura: false,
  authToken: null,
};

export default initialAuthStore;
