import loadable from '@loadable/component';
import { Redirect, Route, Switch } from 'react-router-dom';

const RFO_BetaLanding = loadable(() => import('../apps/RFO/BetaLanding'));
const RFO_Venues = loadable(() => import('../apps/RFO/Venues/Venues'));
const RFO_CityHeroDashboard = loadable(
  () => import('../apps/RFO/HomepageDetails/CityPage/CityHero')
);
const RFO_DeepLinks = loadable(() => import('../apps/RFO/DeepLinks/DeepLinks'));
const RFO_Expenses = loadable(() => import('../apps/RFO/Expenses/ExpensesContainer'));
const RFO_HomepageDetails = loadable(
  () => import('../apps/RFO/HomepageDetails/HomepageDetailsContainer')
);
const RFO_Manage = loadable(() => import('../apps/RFO/Manage/ManageProgramController'));
const RFO_MembershipDashboard = loadable(
  () => import('../apps/RFO/MembershipDashboard/MembershipDashboard')
);
const RFO_NationalDetails = loadable(() => import('../apps/RFO/NationalDetails/NationalDetails'));
const RFO_Neighborhoods = loadable(() => import('../apps/RFO/Neighborhoods/Neighborhoods'));
const RFO_PromoCodes = loadable(() => import('../apps/RFO/PromoCodes/PromoCodes'));
const RFO_Settings = loadable(() => import('../apps/RFO/Settings/OrgSettingsContainer'));
const RFO_Staff = loadable(() => import('../apps/RFO/Staff/StaffPage'));
const RFO_VoloFeedback = loadable(() => import('../apps/RFO/VoloFeedback/VoloFeedbackContainer'));
const RFO_IncidentReportsDashboard = loadable(
  () => import('../apps/RFO/HostForms/IncidentReports/Dashboard')
);
const RFO_IncidentReportPage = loadable(
  () => import('../apps/RFO/HostForms/IncidentReports/IncidentReport')
);
const RFO_RentalDashboard = loadable(
  () => import('../apps/RFO/Rentals/RentalDashboard/RentalDashboard')
);
const RFO_ShiftLogsDashboard = loadable(
  () => import('../apps/RFO/HostForms/ShiftLogs/Dashboard/ShiftLogsDashboard')
);
const RFO_ShiftLogPage = loadable(
  () => import('../apps/RFO/HostForms/ShiftLogs/ShiftLog/ShiftLog')
);
const RFO_RentalPage = loadable(() => import('../apps/RFO/Rentals/Rental'));
const RFO_ShiftLogLinks = loadable(() => import('../apps/RFO/ShiftLogs'));
const RfoCreateV2 = loadable(() => import('../apps/RFO/CreateV2'));
const RFO_RentalCreation = loadable(() => import('../apps/RFO/Rentals/RentalCreation'));
const HostDashboard = loadable(() => import('../apps/RFO/HostDashboard/HostDashboardContainer'));
const RfoLeaguesV2 = loadable(() => import('../apps/RFO/HostLeagues/HostLeagueListContainer'));
const RfoLeaguesBeta = loadable(
  () => import('../apps/RFO/HostLeagues/HostLeagueListContainerBeta')
);
const MessageCenterController = loadable(
  () => import('../apps/RFO/MessageCenter/MessageCenterController')
);
const Players = loadable(() => import('../apps/RFO/PlayersV2'));
const Ratings = loadable(() => import('../apps/RFO/Ratings'));
const StripeAccount = loadable(() => import('../apps/RFO/Settings/StripeAccount'));
const NationalVoloPassMembershipSettings = loadable(
  () => import('../apps/RFO/NationalMembershipSettings/VoloPassTiers')
);
const ErrorPage = loadable(() => import('../pages/ErrorPage'));

const RfoController = () => (
  <Switch>
    <Route exact path="/rfo/stripe-setup" component={StripeAccount} />

    <Route exact path="/rfo/leagues" component={RfoLeaguesV2} />
    <Route exact path="/rfo/beta/leagues" component={RfoLeaguesBeta} />
    <Route exact path="/rfo/players" component={Players} />

    <Route exact path="/rfo/ratings" component={Ratings} />
    <Route exact path="/rfo/messages/compose" component={MessageCenterController} />
    <Route exact path="/rfo/messages/review" component={MessageCenterController} />
    <Route exact path="/rfo/messages/templates" component={MessageCenterController} />
    <Route exact path="/rfo/messages/history" component={MessageCenterController} />
    <Route exact path="/rfo/messages/unsubscribes" component={MessageCenterController} />

    <Route exact path="/rfo/create" render={() => <Redirect to="/rfo/create/start" />} />
    <Route exact path="/rfo/create/rental" component={RFO_RentalCreation} />
    <Route path="/rfo/create/:step" component={RfoCreateV2} />

    <Route
      exact
      path="/rfo/create-dropin"
      render={() => <Redirect to="/rfo/create-dropin/start" />}
    />

    <Route exact path="/rfo/dashboard" component={HostDashboard} />
    <Route exact path="/rfo/staff" component={RFO_Staff} />
    <Route exact path="/rfo/promo-codes" component={RFO_PromoCodes} />
    <Route exact path="/rfo/approved-venues" component={RFO_Venues} />
    <Route exact path="/rfo/neighborhoods" component={RFO_Neighborhoods} />
    <Route exact path="/rfo/city-page-details" component={RFO_HomepageDetails} />
    <Route exact path="/rfo/city-hero-details" component={RFO_CityHeroDashboard} />
    <Route exact path="/rfo/national-page-details" component={RFO_NationalDetails} />
    <Route exact path="/rfo/volo-feedback" component={RFO_VoloFeedback} />
    <Route exact path="/rfo/daily-dropins" component={RFO_MembershipDashboard} />
    <Route
      exact
      path="/rfo/membership-dashboard"
      render={() => <Redirect to="/rfo/daily-dropins" />}
    />

    <Route exact path="/rfo/expenses" component={RFO_Expenses} />
    <Route exact path="/rfo/deep-links" component={RFO_DeepLinks} />

    <Route exact path="/rfo/beta" component={RFO_BetaLanding} />
    <Route exact path="/rfo/beta/shift-log-links" component={RFO_ShiftLogLinks} />

    <Route path="/rfo/league/:id/:which_page" component={RFO_Manage} />

    <Route exact path="/rfo/settings" render={() => <Redirect to="/rfo/settings/general" />} />
    <Route path="/rfo/settings" component={RFO_Settings} />

    <Route exact path="/rfo/incident-reports" component={RFO_IncidentReportsDashboard} />
    <Route path="/rfo/incident-reports/:incidentReportId" component={RFO_IncidentReportPage} />

    <Route exact path="/rfo/rentals" component={RFO_RentalDashboard} />
    <Route path="/rfo/rental/:rentalId" component={RFO_RentalPage} />

    <Route exact path="/rfo/shift-logs" component={RFO_ShiftLogsDashboard} />
    <Route exact path="/rfo/shift-logs/:shiftLogId" component={RFO_ShiftLogPage} />

    <Route
      exact
      path="/rfo/membership-settings/volo-pass"
      component={NationalVoloPassMembershipSettings}
    />

    <Route component={ErrorPage} />
  </Switch>
);

export default RfoController;
