import { graphql } from 'graphql-schema';

export const ADD_PAYMENT_SOURCE = graphql(`
  mutation addUserPaymentSource($tokenId: String!) {
    addUserPaymentSource(tokenId: $tokenId) {
      success
    }
  }
`);

export const SET_PAYMENT_SOURCE_DEFAULT = graphql(`
  mutation setDefaultUserPaymentSource($sourceId: String!) {
    setDefaultUserPaymentSource(sourceId: $sourceId) {
      success
    }
  }
`);

export const DELETE_PAYMENT_SOURCE = graphql(`
  mutation deleteUserPaymentSource($sourceId: String!) {
    deleteUserPaymentSource(sourceId: $sourceId) {
      success
    }
  }
`);
