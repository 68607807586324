import { useEffect, useState, type FC } from 'react';
import classNames from 'classnames/bind';
import { detect } from 'detect-browser';
import { useSuspenseQuery } from '@apollo/client';
import { graphql } from 'graphql-schema';
import { useLocation, useRouteMatch } from 'react-router';
import { checkCity, dashSpaceSwap } from '../../../../utils/url-search-helpers';
import TopBar from './TopBar';
import StickyBottomBar from './StickyBottomBar';
import styles from '../../../../styles/main.module.scss';
import DiscoverBar from './DiscoverBar';
import hasuraClient from '../../../../apollo/hasuraClient';

const cx = classNames.bind(styles);

type Props = {
  /**
   * Hides the top bar with links to contact us, player dashboard, admin dashboard, etc.
   * @default false
   * */
  hideTopBar?: boolean;
  /**
   * Hides the bottom bar with Play Volo, Volo Kids, Info, etc.
   * @default false
   * */
  hideStickyBottomBar?: boolean;
  /**
   * Hides the discover bar with links to various discover pages.
   * @default false
   */
  hideDiscoverBar?: boolean;
};

const HEADER_ORGANIZATIONS_QUERY = graphql(`
  query HeaderOrganizationsQuery {
    organizations(where: { is_discoverable: { _eq: true } }, order_by: { name: asc }) {
      _id
      name
      logo
      sport_details {
        _id
        sport_name
      }
    }
  }
`);

const Header: FC<Props> = ({
  hideTopBar = false,
  hideDiscoverBar = false,
  hideStickyBottomBar = false,
}) => {
  const browserOS = detect()?.os;
  const isMobile = browserOS === 'iOS' || browserOS === 'Android OS';

  const [sticky, setSticky] = useState(false);
  const [hideNav, setHideNav] = useState(true);
  const [hideSports, setHideSports] = useState(isMobile);

  const { path } = useRouteMatch();
  const location = useLocation();

  // verifies that path is a valid org name (ex. /Super-Sportz)
  // otherwise sets cityName to '' (ex. /about-us)
  const checkCityPath = checkCity({ location });
  const cityName = dashSpaceSwap(checkCityPath, 'toSpace');

  const { data } = useSuspenseQuery(HEADER_ORGANIZATIONS_QUERY, {
    client: hasuraClient,
  });

  const cityOptions = data.organizations.map(({ name, _id }) => ({
    value: _id,
    label: name,
  }));

  const selectedOrg = data.organizations.find(({ name }) => name === cityName);

  const organizationSports =
    selectedOrg?.sport_details.map(sport => ({
      sportName: sport.sport_name,
    })) ?? [];

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 40) {
        if (!sticky) setSticky(true);
      } else if (sticky) setSticky(false);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [sticky]);

  return (
    <div id="full-header" className={cx('header', sticky && 'squeeze')}>
      {!hideTopBar && <TopBar />}
      {!hideStickyBottomBar && (
        <StickyBottomBar
          cityName={cityName}
          cityOptions={cityOptions}
          hideNav={hideNav}
          sticky={sticky}
          organizationSports={organizationSports}
          logo={selectedOrg?.logo ?? undefined}
          setHideNav={setHideNav}
          hideSports={hideSports}
          setHideSports={setHideSports}
          isMobile={isMobile}
          goToNational={path === `/${dashSpaceSwap(cityName, 'toDash')}`}
        />
      )}
      {!hideDiscoverBar && <DiscoverBar />}
    </div>
  );
};

export default Header;
