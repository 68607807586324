import { graphql } from 'graphql-schema';
import { useSuspenseQuery } from '@apollo/client';
import hasuraClient from '../apollo/hasuraClient';

const ORGANIZATIONS = graphql(`
  query OrganizationsList {
    organizations(order_by: { name: asc }) {
      _id
      name
      external_id
      is_volo_pass_active
      volo_pass_monthly_plan_id
      volo_pass_annual_plan_id
    }
  }
`);

const useOrganizations = () => {
  const result = useSuspenseQuery(ORGANIZATIONS, { client: hasuraClient });

  return { ...result, organizations: result.data.organizations };
};

export default useOrganizations;
