import { graphql } from 'graphql-schema';

export default graphql(`
  query getUserPaymentSources {
    getUserPaymentSources {
      id
      last4
      isDefault
      isExpired
      exp_month
      exp_year
      brand
    }
  }
`);

export type CurrentUserPaymentSourcesData = {
  getUserPaymentSources: {
    last4: number;
    isDefault: boolean;
    isExpired: boolean;
    exp_month: number;
    exp_year: number;
    brand: string;
    id: string;
  }[];
};
