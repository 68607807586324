/* eslint-disable max-len */
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import Picture from '../../../components/Picture/Picture';

const cx = classNames.bind(styles);

type VoloAppProps = {
  hideApp?: boolean;
};

const VoloApp = ({ hideApp = false }: VoloAppProps) => (
  <div className={cx('voloApp')}>
    <div className="container">
      {!hideApp && (
        <div className="row">
          <div className="col-12 col-md-6">
            <h1 className={cx('fancyText')}>Get the Volo App</h1>
            <div className={cx('feature')}>
              <div className="row">
                <div className="col-2 px-2">
                  <Picture
                    src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/SignUp.png"
                    alt="feature icon"
                  />
                </div>
                <div className="col-10">
                  <h4>Sign up to play</h4>
                  <p>
                    Discover what practices, events, and leagues we have available and sign up
                    directly in the app to reserve your spot.
                  </p>
                </div>
              </div>
            </div>
            <div className={cx('feature')}>
              <div className="row">
                <div className="col-2 px-2">
                  <Picture
                    src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/RSVP.png"
                    alt="feature icon"
                  />
                </div>
                <div className="col-10">
                  <h4>RSVP for activities</h4>
                  <p>
                    Let your team know if you are coming to tonight’s league to avoid a forfeit.
                  </p>
                </div>
              </div>
            </div>
            <div className={cx('feature')}>
              <div className="row">
                <div className="col-2 px-2">
                  <Picture
                    src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/Chat.png"
                    alt="feature icon"
                  />
                </div>
                <div className="col-10">
                  <h4>Chat with your team</h4>
                  <p>
                    Talk to your teammates in preparation for your league without having to provide
                    your personal information, until you’re ready.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <Picture
              className={cx('volo')}
              src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-app.png"
              alt="volo app"
            />
          </div>
        </div>
      )}
    </div>
  </div>
);

export default VoloApp;
