import { type FC, useMemo } from 'react';
import { View, Heading, Select } from '@rivallapp/volosports-components';

import { type VoloPassCitySelectProps } from './types';
import useOrganizations from '../../../../../../hooks/useOrganizations';

const VoloPassCitySelect: FC<VoloPassCitySelectProps> = ({ selectedCityId, setSelectedCityId }) => {
  const { organizations } = useOrganizations();

  const organizationOptions = useMemo(() => {
    return organizations?.map(({ external_id, name }) => ({
      value: external_id,
      label: name,
    }));
  }, [organizations]);

  const onValueChange = (value: string) => setSelectedCityId(value);

  return (
    <View marginTop={4}>
      <Heading fontSize={16} color="primary.600">
        Select Your City
      </Heading>
      <Select selectedValue={selectedCityId} onValueChange={onValueChange} flex={1}>
        {organizationOptions?.map(({ value, label }) => (
          <Select.Item key={value} value={value} label={label} />
        ))}
      </Select>
    </View>
  );
};

export default VoloPassCitySelect;
