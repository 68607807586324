import { graphql } from 'graphql-schema';

const GET_UPGRADE_PRICING = graphql(`
  query getVoloPassUpgradePricing($planId: String!, $promoCode: String) {
    getVoloPassUpgradePricing(planId: $planId, promoCode: $promoCode) {
      id
      proratedAmountCents
      subtotalCents
      taxCents
      totalCents
    }
  }
`);

export default GET_UPGRADE_PRICING;
