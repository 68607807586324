import { gql, type TypedDocumentNode } from '@apollo/client';
import type { GenderEnum_TS } from '@rivallapp/volosports-components';
import { graphql } from 'graphql-schema';
import type {
  StripeMembershipIntervalEnum,
  StripeSubscriptionStatus,
} from '../../shared/stripe-enums';

interface GetUserByInfoInput {
  input: {
    search: string;
    which: string;
  };
  userId: string;
}

export interface GetUserByInfoData {
  getUserByInfo: {
    _id: string;
    isVoloPassMember: boolean;
    membershipExpires?: string;
    firstName: string;
    lastName: string;
    gender?: GenderEnum_TS;
    email: string;
    phone_number?: string;
    countryCode?: string;
    picture?: string;
    _created: string;
    birthday: string;
    status: string;
    username: string;
    deactivated: boolean;
    is_host: boolean;
    homeOrganizationObject?: {
      _id: string;
      name: string;
    };
  };
  activeMembershipForUser: {
    userId: string;
    planExpiresAt?: string;
    planStartsAt?: string;
    status?: StripeSubscriptionStatus;
    plan?: {
      id: string;
      interval: StripeMembershipIntervalEnum;
    };
    metadata?: {
      organization?: string;
      organizationId?: string;
      userId?: string;
    };
  };
}

export const USER_BY_INFO_QUERY: TypedDocumentNode<GetUserByInfoData, GetUserByInfoInput> = gql`
  query getUserByInfo($input: UserInfoInput!, $userId: ID!) {
    getUserByInfo(input: $input) {
      _id
      isVoloPassMember
      membershipExpires
      firstName
      lastName
      gender
      email
      phone_number
      countryCode
      picture
      _created
      birthday
      status
      username
      deactivated
      is_host
      homeOrganizationObject {
        _id
        name
      }
    }
    activeMembershipForUser(userId: $userId) {
      userId
      planExpiresAt
      planStartsAt
      status
      plan {
        id
        interval
      }
      metadata {
        organization
        organizationId
        userId
      }
    }
  }
`;

export const PLAYER_WAIVERS_QUERY = gql`
  query getUserWaivers($userId: ID!) {
    getUserWaivers(userId: $userId) {
      organizationId
      registrant_id
      waiver_name
      waiver_id
      waiver_type
      program_name
      program_id
      season
      signed
      date_signed
      reminders_sent {
        date_sent
        sent_by
      }
    }
  }
`;

export const WAIVER_QUERY = graphql(`
  query waiver($_id: uuid!) {
    waivers_by_pk(_id: $_id) {
      _id
      name
    }
  }
`);
