import React, { type ComponentProps } from 'react';
import { CollapseForm } from '../../../custom-components/ColllapseForm';
import { Box } from '../../../base-components';

interface HostProps extends Omit<ComponentProps<typeof CollapseForm>, 'title' | 'view'> {
  host_response: boolean;
  experienceResponses?: string[];
  cohostNamesResponse?: string | null;
  hostsComments?: string | null;
}

const Hosts: React.FC<HostProps> = ({
  host_response = false,
  experienceResponses = [],
  cohostNamesResponse,
  hostsComments,
  children,
  ...props
}) => {
  return (
    <CollapseForm
      title="Hosts"
      view="read"
      toggleQuestion="Any issues to report with co-hosts during your shift?"
      toggleResponse={host_response}
      {...props}
    >
      <Box paddingTop={3}>
        <CollapseForm.MultiSelect
          label="Experience Feedback:"
          selectedResponses={experienceResponses}
        />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Co-host Names:" response={cohostNamesResponse ?? undefined} />
      </Box>
      <Box paddingTop={3}>
        <CollapseForm.TextArea label="Comments/Notes:" response={hostsComments ?? undefined} />
      </Box>
      {children}
    </CollapseForm>
  );
};

export default Hosts;
