import { ShiftLogSection, ShiftLogStatus } from '../../shared/shift-log-enums';

export type PulseCheckRating = 1 | 2 | 3 | 4 | 5;

export interface IShiftLogsFiltersStore {
  organizations: string[];
  day_of_week: string[];
  sports: string[];
  venue: { _id: string; name: string }[];
  created_by: { _id: string; fullName: string }[];
  submitted_after: string;
  submitted_before: string;
  reviewer: { _id: string; fullName: string }[];
  status: ShiftLogStatus[];
  sections_resolved: ShiftLogSection[];
  sections_unresolved: ShiftLogSection[];
  rating: PulseCheckRating[];
  sort_order: 'ASCENDING' | 'DESCENDING';
}

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);
// all my troubles seemed so far away

const initialShiftLogsFiltersStore: IShiftLogsFiltersStore = {
  organizations: [],
  day_of_week: [],
  sports: [],
  venue: [],
  created_by: [],
  submitted_after: yesterday.toISOString(),
  submitted_before: '',
  reviewer: [],
  status: [ShiftLogStatus.new, ShiftLogStatus.in_progress],
  sections_resolved: [],
  sections_unresolved: [],
  rating: [],
  sort_order: 'DESCENDING',
};

export default initialShiftLogsFiltersStore;
