import { graphql } from '@apollo/client/react/hoc';
import * as _ from 'lodash-es';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { branch, compose, withProps } from 'recompose';
import CURRENT_USER_QUERY from './CURRENT_USER_QUERY';
import hasuraClient from '../../apollo/hasuraClient';

/**
 * ! MIGRATION NOTE
 *
 * This is used in a bunch of older places so be careful when updating. If you're able
 * to de-compose, do it!
 */

const withCurrentUser = () => WrappedComponent =>
  compose(
    withRouter,
    graphql(CURRENT_USER_QUERY, {
      options: {
        client: hasuraClient,
        fetchPolicy: 'network-only',
      },
      props: ({ data: { loading, currentUser } }) => ({
        loading,
        currentUser,
      }),
    }),
    branch(
      ({ loading }) => loading,
      () => () => null
    ),
    withProps(props => {
      const { currentUser, history, organizationId: pastOrgId, loading } = props;

      if (!loading && !currentUser)
        return {
          isAuthenticated: false,
        };

      const query = qs.parse(_.get(history, 'location.search'), { ignoreQueryPrefix: true });

      /**
       * ! MIGRATION NOTE
       * Previously, the user's home organization would be the first choice here.
       * Watch out for older logic that uses that, or this "pastOrgId".
       * For RFO pages, we should only use the query param and/or `organization_admins`.
       */
      let organizationId = pastOrgId || currentUser.organization_admins[0]?.organization;

      const queryOrgId = _.get(query, 'organization') || organizationId;

      const roles = currentUser?.roles?.map(({ role }) => role);

      if (_.isEmpty(queryOrgId) || queryOrgId === organizationId) {
        return {
          currentUser: {
            ...currentUser,
            organizationId,
          },
          organizationId,
          isAuthenticated: !!currentUser,
          roles,
        };
      }

      const orgIds = currentUser?.organization_admins?.map(({ organization }) => organization);

      const ownerIsOrgAdmin = orgIds.includes(queryOrgId);

      // city admin
      if (_.includes(roles, 'OWNER') && ownerIsOrgAdmin) {
        organizationId = queryOrgId;
      }
      // super admin
      if (_.includes(roles, 'ADMIN')) {
        organizationId = queryOrgId;
      }

      return {
        currentUser: {
          ...currentUser,
          organizationId,
        },
        organizationId,
        isAuthenticated: !!currentUser,
        roles: _.get(currentUser, 'roles'),
      };
    })
  )(WrappedComponent);

export default withCurrentUser;
