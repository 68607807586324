import PropTypes from 'prop-types';
import VerticalTable from './VerticalTable';
import HorizontalTable from './HorizontalTable';

const Table = props => {
  const { data, direction } = props;

  if (!direction) {
    return <div>Please indicate a table direction</div>;
  }

  if (!Object.values(data)?.length) {
    return <div>No data to display</div>;
  }

  if (direction === 'vertical') {
    return <VerticalTable data={data} />;
  }

  return <HorizontalTable data={data} />;
};

Table.propTypes = {
  direction: PropTypes.string,
  data: PropTypes.arrayOf({}),
};

Table.defaultProps = {
  direction: undefined,
  data: PropTypes.arrayOf({}),
};

export default Table;
