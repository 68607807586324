import React, { Suspense, useCallback, useEffect, useRef, useTransition } from 'react';
import { Text } from '@rivallapp/volosports-components';
import { ShiftLogsListRow } from './components';
import ShiftLogsListSkeleton from './components/ShiftLogsListSkeleton';
import { type ParsedShiftLog } from '../../hooks/useParseShiftLogData';
import type { ShiftLogsListRowProps } from './components/ShiftLogsListRow';

type ShiftLogsListProps = {
  shiftLogs: ParsedShiftLog[];
  hasMore?: boolean;
  onListEndReached?: () => any;
  rowLinkProps?: ShiftLogsListRowProps['linkProps'];
};

const ShiftLogsList: React.FC<ShiftLogsListProps> = ({
  shiftLogs,
  hasMore = false,
  onListEndReached = () => undefined,
  rowLinkProps,
}) => {
  const [isPending, startTransition] = useTransition();
  const observerRef = useRef<HTMLDivElement>(null);

  const shouldFetchMore = hasMore && !isPending;

  const handleListEndReached = useCallback(() => {
    if (!shouldFetchMore) return;
    startTransition(() => {
      onListEndReached();
    });
  }, [onListEndReached, shouldFetchMore]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting) handleListEndReached();
      },
      {
        threshold: 0,
      }
    );

    const ref = observerRef.current!;
    if (ref) observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [handleListEndReached]);

  return (
    <>
      {shiftLogs.map(log => (
        <ShiftLogsListRow key={log._id} linkProps={rowLinkProps} {...log} />
      ))}
      {isPending && <ShiftLogsListSkeleton />}
      {!hasMore && (
        <Text m={2} alignSelf="center" bold>
          End of results
        </Text>
      )}
      {/* intersection observer element */}
      <div ref={observerRef} />
    </>
  );
};

const ShiftLogsListWrapper = (props: ShiftLogsListProps) => (
  <Suspense fallback={<ShiftLogsListSkeleton />}>
    <ShiftLogsList {...props} />
  </Suspense>
);

export default ShiftLogsListWrapper;
