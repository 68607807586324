import { graphql } from 'graphql-schema';

export default graphql(`
  query currentUser {
    currentUser {
      _id
      roles {
        _id
        role
      }
      email
      firstName: first_name
      lastName: last_name
      picture: profile_picture
      organizationId: home_organization
      gender
      privacy_preferences {
        _id
        share_email
        share_phone
      }
      organization_admins {
        _id
        organization
        organizationByOrganization {
          _id
          name
        }
      }
    }
  }
`);
