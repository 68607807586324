import React, { Suspense, useCallback } from 'react';
import { Text, View } from '@rivallapp/volosports-components';
import { useSuspenseQuery } from '@apollo/client';
import { GenericErrorBoundary } from '../../../components';
import GET_USER_SHIFT_LOGS from './gql/GET_USER_SHIFT_LOGS';
import useParseShiftLogData from '../../../apps/RFO/HostForms/ShiftLogs/hooks/useParseShiftLogData';
import ShiftLogsList from '../../../apps/RFO/HostForms/ShiftLogs/Dashboard/ShiftLogsList';
import ShiftLogsListSkeleton from '../../../apps/RFO/HostForms/ShiftLogs/Dashboard/ShiftLogsList/components/ShiftLogsListSkeleton';
import hasuraClient from '../../../apollo/hasuraClient';

type UserShiftLogsTabProps = {
  userId: string;
};

const UserShiftLogsTab: React.FC<UserShiftLogsTabProps> = ({ userId }) => {
  const { data, fetchMore } = useSuspenseQuery(GET_USER_SHIFT_LOGS, {
    errorPolicy: 'none',
    client: hasuraClient,
    variables: {
      userId,
    },
  });

  const user = data.users_by_pk;
  if (!user) throw new Error('User not found.');

  const totalCount = user.shiftLogsByCreatedBy_aggregate.aggregate?.count ?? 0;
  const shiftLogs = useParseShiftLogData(user.shiftLogsByCreatedBy);
  const hasMore = shiftLogs.length < totalCount;

  const handleFetchMore = useCallback(async () => {
    if (!hasMore) return;
    await fetchMore({
      variables: {
        offset: shiftLogs.length,
      },
    });
  }, [hasMore, fetchMore, data]);

  return (
    <View>
      <Text textAlign="center" type="h2">
        Shift Logs
      </Text>
      <Text textAlign="center" type="caption" marginTop="0.5" marginBottom="1">
        Total: {totalCount}
      </Text>
      <ShiftLogsList
        shiftLogs={shiftLogs}
        hasMore={hasMore}
        onListEndReached={handleFetchMore}
        rowLinkProps={{ target: '_blank' }}
      />
    </View>
  );
};

const UserShiftLogsTabWrapper: React.FC<UserShiftLogsTabProps> = props => (
  <GenericErrorBoundary>
    <Suspense fallback={<ShiftLogsListSkeleton />}>
      <UserShiftLogsTab {...props} />
    </Suspense>
  </GenericErrorBoundary>
);

export default UserShiftLogsTabWrapper;
