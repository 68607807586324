import FroalaEditor from 'react-froala-wysiwyg';
import { Suspense, type ComponentProps, type FC } from 'react';
import getToolbarButtons from './tools/getToolbarButtons';
import { froalaKey, hasuraURL } from '../../environment';
import Loading from '../Loading';
import GenericErrorBoundary from '../ErrorBoundaries/GenericError';
import useCurrentUserV2 from '../../hooks/useCurrentUserV2';
import { useAuthStore } from '../../zustand-stores';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'froala-editor/css/froala_style.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'froala-editor/css/froala_editor.pkgd.min.css';
// eslint-disable-next-line import/extensions, import/no-extraneous-dependencies
import 'froala-editor/js/plugins.pkgd.min.js';

type Props = {
  height?: number;
  html?: ComponentProps<typeof FroalaEditor>['model'];
  disableMedia?: boolean;
  organizationId?: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
};

const TextEditor: FC<Props> = props => {
  const {
    disableMedia = false,
    height,
    html,
    onChange,
    onFocus,
    placeholder = 'Edit your content here',
    organizationId,
  } = props;

  const authToken = useAuthStore(store => store.authToken);

  const { organizationId: currentUserOrganizationId } = useCurrentUserV2();

  const imageUploadUrl = `${hasuraURL}/api/v1/public/upload/froala/${organizationId || currentUserOrganizationId}`;

  return (
    <FroalaEditor
      tag="textarea"
      config={{
        ...(onFocus
          ? {
              events: {
                click() {
                  onFocus();
                },
              },
            }
          : {}),
        ...(height ? { height } : {}),
        heightMin: 300,
        placeholder,
        toolbarButtons: getToolbarButtons({ disableMedia }),
        imageUploadURL: imageUploadUrl,
        imageManagerLoadURL: imageUploadUrl,
        imageManagerDeleteURL: imageUploadUrl,
        imageManagerDeleteMethod: 'DELETE',
        requestHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
        key: froalaKey,
      }}
      model={html}
      onModelChange={onChange}
    />
  );
};

export default (props: Props) => (
  <GenericErrorBoundary>
    <Suspense fallback={<Loading type="inline" />}>
      <TextEditor {...props} />
    </Suspense>
  </GenericErrorBoundary>
);
