import { graphql } from 'graphql-schema';

const GET_USER_SHIFT_LOGS = graphql(`
  query GetUserShiftLogs($userId: uuid!, $limit: Int = 20, $offset: Int = 0) {
    users_by_pk(_id: $userId) {
      _id
      shiftLogsByCreatedBy(limit: $limit, offset: $offset) {
        _id
        created_at
        userByCreatedBy {
          _id
          full_name
        }
        day_of_week
        venueByVenue {
          _id
          shorthand_name
        }
        sports {
          _id
          sportBySport {
            _id
            name
          }
        }
        pulse_check_rating
        content_capture_resolution_status
        daily_programming_resolution_status
        equipment_resolution_status
        hosts_resolution_status
        miscellaneous_resolution_status
        players_resolution_status
        referees_resolution_status
        sponsor_bar_resolution_status
        shirts_resolution_status
        technology_resolution_status
        venue_resolution_status
      }

      shiftLogsByCreatedBy_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`);

export default GET_USER_SHIFT_LOGS;
