import classNames from 'classnames/bind';
import type { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from '../../../../styles/main.module.scss';
import Picture from '../../../../components/Picture/Picture';
import { dashSpaceSwap } from '../../../../utils/url-search-helpers';
import { FilterSelect } from '../../../../components';
import Info from './dropdowns/Info';
import PlayVolo from './dropdowns/PlayVolo';

import menu from '../../../../images/icons/menu.svg';
import { StaticUrls } from '../../../../shared/static-urls-enum';

const cx = classNames.bind(styles);

/* Assets */
const VoloLogoSrc =
  'https://rivall-public.s3.us-west-1.amazonaws.com/logos/organization-logos/default.png';

type Props = {
  logo?: string;
  cityName: string;
  cityOptions: { label: string; value: string }[];
  organizationSports: { sportName: string }[];
  sticky: boolean;
  hideNav: boolean;
  setHideNav: (hideNav: boolean) => void;
  hideSports: boolean;
  setHideSports: (hideSports: boolean) => void;
  isMobile: boolean;
  goToNational: boolean;
};

const StickyBottomBar: FC<Props> = props => {
  const {
    cityName,
    cityOptions,
    sticky,
    organizationSports,
    logo,
    goToNational,
    setHideNav,
    hideNav,
    isMobile,
    hideSports,
    setHideSports,
  } = props;

  const { push } = useHistory();
  const updateCityNameUrl = (cityName: string) => push(`/${dashSpaceSwap(cityName, 'toDash')}`);

  return (
    <div id="stickyBottomBar" className={cx('bottomBar', sticky && 'squeeze')}>
      <div className="container-fluid">
        <div className={cx('navRow', 'row', 'justify-content-center')}>
          {/* Logo */}
          <div className={cx('logo-col', 'col-12', 'col-md-auto', 'my-auto')}>
            <Link
              className={cx('navCol')}
              to={cityName && !goToNational ? `/${dashSpaceSwap(cityName, 'toDash')}` : '/'}
            >
              <Picture
                src={logo || VoloLogoSrc}
                alt="Volo Sports logo"
                height={sticky ? '35' : '50'}
                className="d-inline-block align-top"
              />
            </Link>
            <img
              className={cx('menu', 'd-block', 'd-md-none')}
              onClick={() => setHideNav(!hideNav)}
              onKeyDown={() => setHideNav(!hideNav)}
              src={menu}
              alt="show rest of header menu"
            />
          </div>

          <PlayVolo
            cityOptions={cityOptions}
            hideNav={hideNav}
            cityName={cityName}
            organizationSports={organizationSports}
          />

          <div className={cx(hideNav && 'small-hide-col', 'col-12', 'col-md-auto', 'my-auto')}>
            <div className={cx('navCol')}>
              <a href={StaticUrls.VOLOKIDS} target="_blank" rel="noopener noreferrer">
                Volo Kids
              </a>
            </div>
          </div>

          <Info
            cityName={cityName}
            organizationSports={organizationSports}
            hideSports={hideSports}
            hideNav={hideNav}
            isMobile={isMobile}
            setHideSports={setHideSports}
          />

          <div className={cx(hideNav && 'small-hide-col', 'col-12', 'col-md-auto', 'my-auto')}>
            <Link
              to={
                cityName === '' ? '/volo-pass' : `/${dashSpaceSwap(cityName, 'toDash')}/volo-pass`
              }
            >
              Membership
            </Link>
          </div>

          <div
            className={cx(hideNav && 'small-hide-col', 'col-12', 'col-md-auto', 'my-auto')}
            style={{ zIndex: 0 }}
          >
            <div className={cx('chooseCity', 'navCol')}>
              <FilterSelect
                dark
                skinny={sticky}
                placeholder="Choose Your City"
                onChange={option => updateCityNameUrl(option?.label ?? '')}
                labelSingular="City"
                options={cityOptions}
                menuPortalTarget={document.body}
                value={cityOptions.find(({ label }) => label === cityName)}
                inHeaderCity
                clearable
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyBottomBar;
