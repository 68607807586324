import React, { type ComponentProps } from 'react';
import { FontAwesomeIcon, Stack, Tooltip } from '@rivallapp/volosports-components';
import {
  type ShiftLogSection,
  ShiftLogSectionStatus,
  ShiftLogSectionsToFormattedNameMap,
} from '../../../../../shared/shift-log-enums';
import useIconFromShiftLogSection from '../hooks/useIconFromShiftLogSection';

type ShiftLogIconProps = {
  section: ShiftLogSection;
  status: ShiftLogSectionStatus;
  size?: ComponentProps<typeof Stack>['h'];
  toolTipProps?: ComponentProps<typeof Tooltip>;
  containerProps?: ComponentProps<typeof Stack>;
};

const ShiftLogIcon: React.FC<ShiftLogIconProps> = ({
  section,
  status,
  size = '10',
  toolTipProps,
  containerProps,
}) => {
  let colorScheme: ComponentProps<typeof FontAwesomeIcon>['colorScheme'];
  switch (status) {
    case ShiftLogSectionStatus.resolved:
      colorScheme = 'green';
      break;
    case ShiftLogSectionStatus.new:
    case ShiftLogSectionStatus.open:
    case ShiftLogSectionStatus.in_progress:
      colorScheme = 'red';
      break;
    default:
      colorScheme = 'gray';
  }

  const icon = useIconFromShiftLogSection(section);

  return (
    <Tooltip label={ShiftLogSectionsToFormattedNameMap[section]} {...toolTipProps}>
      <Stack
        borderRadius="full"
        colorScheme={colorScheme}
        borderColor={`${colorScheme}.500`}
        w={size}
        h={size}
        borderWidth="1"
        alignItems="center"
        justifyContent="center"
        {...containerProps}
      >
        <FontAwesomeIcon colorScheme={colorScheme} icon={icon} />
      </Stack>
    </Tooltip>
  );
};

export default ShiftLogIcon;
