import {
  type ComponentProps,
  type Dispatch,
  type SetStateAction,
  type FC,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useQuery } from '@apollo/client';
import {
  Modal,
  VoloPassSignupAd,
  View,
  MembershipInterval,
  StripeMembershipIntervalEnum,
  useUserVoloPassMembershipStatus,
} from '@rivallapp/volosports-components';
import { graphql } from 'graphql-schema';

import {
  VoloPassCitySelect,
  VoloPassSignUpHeader,
  VoloPassSignUp,
  VoloPassWaitlistMessage,
} from './components';
import useCurrentUserV2 from '../../../../../hooks/useCurrentUserV2';
import {
  voloPassFindAnnualPrice,
  voloPassFindMonthlyPrice,
} from '../../../../../shared/volo-pass-tiers-enum';
import hasuraClient from '../../../../../apollo/hasuraClient';

const ORGANIZATION_VP_DATA = graphql(`
  query organizationVPData($id: String!) {
    organizations(where: { external_id: { _eq: $id } }, limit: 1) {
      _id
      external_id
      name
      volo_pass_monthly_plan_id
      volo_pass_annual_plan_id
      is_volo_pass_active
    }
  }
`);

interface VoloPassSignUpModalProps extends ComponentProps<typeof Modal> {
  showSignUpModal: boolean;
  setShowSignUpModal: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
  /** Non-member clicks "Sign Up" on Annual tile, OR monthly member clicks "Sign Up"/"Upgrade" */
  annualOverride?: boolean;
  /** If user comes to this modal through the `/cityName/volo-pass page` */
  cityPageId?: string;
}

/** Sign-up modal for Volo Pass.
 *
 * Allows users to select a city and sign up for that city's Volo Pass membership.
 *
 * Displays a waitlist message for cities that do not have Volo Pass enabled.
 *
 * Allows monthly Volo Pass members to upgrade to an annual membership within the same organization.
 *
 * This shouldn't be a page annual members can get to through the site UI
 */
const VoloPassSignUpModal: FC<VoloPassSignUpModalProps> = ({
  showSignUpModal,
  setShowSignUpModal,
  cityPageId,
  annualOverride = false,
  refetch,
  ...props
}) => {
  const { currentUser } = useCurrentUserV2();
  const [selectedCityId, setSelectedCityId] = useState('');

  const { voloPassMembershipType, voloPassMembershipOrganizationId, isVoloPassMember } =
    useUserVoloPassMembershipStatus();

  const defaultOrganizationId =
    voloPassMembershipOrganizationId || cityPageId || currentUser?.home_organization || '';
  const isMonthlyVoloPassMember =
    isVoloPassMember &&
    voloPassMembershipType === MembershipInterval[StripeMembershipIntervalEnum.MONTH].label;

  /** 8/26/2024 - This useEffect is only running when the data initially loads so that we don't have an empty city select.
   * The data fetching is slow and asynchronous and trying to set the state without it,
   * as well as useMemo, useCallback, and setting the defaultValue on the Select all didn't work.
   *
   * It sets the city select to either the organization id for an existing plan or the current user's home org if they're not a member.
   *
   * NOTE: Using the Select to set a new selectedCityId does NOT cause it to run */
  useEffect(() => {
    if (!selectedCityId) setSelectedCityId(defaultOrganizationId);
  }, [defaultOrganizationId, selectedCityId]);

  const { data: selectedOrganizationData, loading: selectedOrganizationDataLoading } = useQuery(
    ORGANIZATION_VP_DATA,
    {
      skip: !selectedCityId && !defaultOrganizationId,
      fetchPolicy: 'cache-and-network',
      variables: {
        id: selectedCityId || defaultOrganizationId!,
      },
      client: hasuraClient,
    }
  );

  // Memoized because real users likely aren't going to be switching through organizations
  const organizationVoloPassDetails = useMemo(() => {
    const { organizations: organization } = selectedOrganizationData || {};
    const {
      volo_pass_monthly_plan_id,
      volo_pass_annual_plan_id,
      name: cityName = '',
      external_id: cityId = '',
      is_volo_pass_active: isActive,
    } = organization?.[0] || {};

    return {
      cityId,
      cityName,
      isActive,
      annualDollarPrice: voloPassFindAnnualPrice(volo_pass_annual_plan_id ?? '') ?? 0,
      monthlyDollarPrice: voloPassFindMonthlyPrice(volo_pass_monthly_plan_id ?? '') ?? 0,
      monthlyPlanId: volo_pass_monthly_plan_id ?? '',
      annualPlanId: volo_pass_annual_plan_id! ?? '',
    };
  }, [selectedOrganizationData]);

  return (
    <Modal
      isOpen={showSignUpModal}
      useRNModal
      onClose={() => setShowSignUpModal(false)}
      size="full"
      {...props}
    >
      <Modal.Content width={{ md: 800 }} minHeight={640} backgroundColor="white.600">
        <Modal.CloseButton />
        <Modal.Body flexDirection={{ md: 'row' }} padding={6}>
          <VoloPassSignupAd maxHeight={300} />
          <View flex={1} paddingLeft={6}>
            <VoloPassSignUpHeader
              isVoloPassCity={!!organizationVoloPassDetails.isActive}
              isMonthlyVoloPassMember={isMonthlyVoloPassMember}
            />
            {/* Monthly members can only upgrade from their curent plan's city */}
            {!isMonthlyVoloPassMember && (
              <VoloPassCitySelect
                selectedCityId={selectedCityId}
                setSelectedCityId={setSelectedCityId}
              />
            )}
            {/* TODO this component is erroring :( (most likely an out of order state update or side effect) */}
            {organizationVoloPassDetails.isActive ? (
              <VoloPassSignUp
                organizationVoloPassDetails={organizationVoloPassDetails}
                setShowSignUpModal={setShowSignUpModal}
                annualOverride={annualOverride || isMonthlyVoloPassMember}
                refetch={refetch}
              />
            ) : (
              <VoloPassWaitlistMessage
                cityName={organizationVoloPassDetails.cityName}
                loading={selectedOrganizationDataLoading}
              />
            )}
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default VoloPassSignUpModal;
